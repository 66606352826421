<template>
  <div>
    <p>当前无内容</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
p{
  color: red;
  font-size: 20px;
}
</style>
